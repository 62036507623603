<template>
  <div class="solutions" :class="`bg-${content.backgroundColor}`">
    <div class="solutions-name">
      <img
        v-if="content.icon?.src"
        class="solutions-logo"
        :src="content.icon.src"
        alt=""
      />
      <span class="title"> {{ content.title }} </span>
    </div>

    <div class="solutions-inner">
      <div class="solutions-content">
        <div
          v-if="content.logo?.src"
          class="solution-content-top"
          @click="logoClick"
        >
          <img
            class="solution-content-top-logo"
            :src="content.logo.src"
            alt=""
          />
        </div>

        <span class="h5" v-html="content.subtitle"></span>
        <div class="solutions-text" v-html="content.text"></div>

        <Action
          v-if="content.commandLink?.url"
          class="solution--command"
          :content="{
            title: content.commandLink.title,
            url: content.commandLink.url,
            modifiers: ['btn'],
          }"
        />

        <div class="solutions--cta">
          <Action
            v-if="content.employeesLink?.url"
            :content="{
              title: content.employeesLink.title,
              url: content.employeesLink.url,
              icon: {
                name: 'ui-employee',
              },
              modifiers: ['btn', 'black', 'small', 'reverse'],
            }"
          />
          <Action
            v-if="content.merchantLink?.url"
            :content="{
              title: content.merchantLink.title,
              url: content.merchantLink.url,
              icon: {
                name: 'ui-merchant',
              },
              modifiers: ['btn', 'black', 'small', 'reverse'],
            }"
          />
        </div>

        <img
          v-if="content.picture"
          v-src="content.picture"
          class="solution-picture"
          :alt="content.picture?.alt"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { Picture, Uri } from '@/types'

export interface SolutionsType {
  title: string
  icon: Picture
  logo: Picture
  picture: Picture
  subtitle: string
  text: string
  backgroundColor: string
  commandLink?: Uri
  employeesLink?: Uri
  merchantLink?: Uri
  plyr?: void
}

const props = defineProps({
  content: {
    type: Object as PropType<SolutionsType>,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
})

// Emit
const emit = defineEmits<{
  'open-collapse': [value: number]
}>()

const logoClick = () => {
  emit('open-collapse', props.index)
}
</script>

<style lang="scss" scoped>
$bp: 'l';
$accent-10: var(--c-accent-10);
$accent-60: var(--c-accent-60);

.solutions {
  position: relative;
  overflow: hidden;
  color: var(--c-text);
  border: 2px solid rgba(109, 119, 135, 20%);
  border-radius: 4rem;
  transform-origin: top left;

  // aspect-ratio: 16 / 9;

  &.is-visible {
    @include mq($bp) {
      box-shadow: 0 4px 40px 0 var(--c-accent-30);
    }
  }

  &.open {
    background: $accent-10;
    border-color: transparent;
  }

  @include mq($until: $bp) {
    /* stylelint-disable-next-line declaration-no-important */
    position: relative !important;
    min-width: 90vw;
    /* stylelint-disable-next-line declaration-no-important */
    transform: none !important;
  }

  @include mq($bp) {
    background: $accent-10;
    border-color: $accent-60;
  }
}

.solutions-name {
  position: absolute;
  top: 4rem;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: baseline;
  width: 8rem;
  height: calc(100% - 4rem);
  padding-top: 7rem;
  transition: all 0.1s ease-in-out;

  span {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    font-family: $ff-alt;
    font-size: 2.6rem;
    font-weight: 500;
  }

  .is-visible & {
    opacity: 0;
  }

  @include mq($until: $bp) {
    display: none;
  }
}

.h5 {
  margin-bottom: 1rem;
}

.solutions-inner {
  padding: 1.2rem 1.8rem;

  .is-visible & {
    transform: translateX(0);
  }

  @include mq($until: $bp) {
    height: 6.5rem;
  }

  @include mq($bp) {
    height: 100%;
    transform: translateX(4rem);
    padding: 4rem 6rem;
    transition: all 0.2s ease-in-out;
  }
}

.solutions-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;

  @include mq($until: $bp) {
    position: relative;
  }

  @include mq($bp) {
    width: 50%;
  }
}

.solutions-text {
  margin-bottom: 5rem;
}

.solutions--cta {
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
  padding-top: 1.6rem;
  border-top: 1px solid rgba(109, 119, 135, 20%);
  gap: 1rem;

  :deep(.action__icon) {
    stroke: $c-black;
    fill: transparent;
  }

  @include mq(xxl) {
    gap: 2.4rem;
  }

  @include mq($until: $bp) {
    margin-top: auto;
    margin-bottom: 24rem;
    padding-top: 2rem;
  }
}

.solution-content-top {
  position: relative;
  display: block;
  width: 100%;
  height: 5rem;
  max-height: 5rem;
  margin-bottom: 2rem;

  @include mq($until: $bp) {
    height: 4rem;
  }
}

.solution-content-top-logo {
  width: initial;
  height: 100%;
}

.solutions-logo {
  position: absolute;
  width: fit-content;
  height: 5rem;

  .solutions-name & {
    top: 0;
    left: 1.7rem;
    max-width: inherit;
  }
}

.solution-picture {
  position: absolute;
  bottom: 0;
  pointer-events: none;
  width: 100%;
  object-fit: cover;
  transition: all 0.1s ease-in;

  @include mq($until: $bp) {
    left: 0;
    height: 20rem;
    opacity: 0;
    transform: translateY(-10rem) scale(1.11);

    .open & {
      transform: translateY(0) scale(1.11);
      opacity: 1;
      transition: all 0.1s 0.2s ease-out;
    }
  }

  @include mq($bp) {
    right: 0;
    width: 45%;
    height: 100%;
  }
}

.solution--command {
  margin-bottom: 2rem;
}
</style>
