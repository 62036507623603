import { Observer } from 'gsap/Observer'

type Callback = (self?: Observer) => void

type EventType = 'onMove' | 'onScroll' | 'onAppear' | 'onDrag'
type Event = (type: EventType, callback: Callback, el?: Element) => void

type ObserverType = {
  interOBS: IntersectionObserver | null
  isActive: boolean
  onDrag: Array<Callback>
  onMove: Array<Callback>
  onScroll: Array<Callback>
  onAppear: Array<{ el: Element; callback: Callback }>
  add: Event
  remove: Event
  init: () => void
}

const observer: ObserverType = {
  interOBS: null,
  isActive: true,
  onDrag: [],
  onMove: [],
  onScroll: [],
  onAppear: [],

  add(type, callback: (self?: Observer) => void, el?: Element) {
    this.isActive = false

    if (type === 'onAppear' && el) {
      this.onAppear.push({ el, callback })
      this.interOBS?.observe(el)
    } else if (this[type]) {
      this[type as Extract<EventType, 'onScroll' | 'onMove'>].push(callback)
    }

    this.isActive = true
  },

  remove(type, callback, el?: Element) {
    this.isActive = false

    if (type === 'onAppear' && el) {
      this.interOBS?.unobserve(el)
      const itemToRemove = this.onAppear.find(item => item.el === el)

      if (itemToRemove) {
        const indexToRemove = this.onAppear.indexOf(itemToRemove)
        this.onAppear.splice(indexToRemove, 1)
      }
    } else if (this[type]) {
      const index =
        this[type as Extract<EventType, 'onScroll' | 'onMove'>].indexOf(
          callback
        )
      this[type].splice(index, 1)
    }

    this.isActive = true
  },

  init() {
    const IOOptions = { threshold: 0.3 }
    this.interOBS = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          this.onAppear.forEach(({ el, callback }) => {
            el === entry.target && callback()
          })
        }
      })
    }, IOOptions)

    Observer.create({
      target: window,
      type: 'pointer,scroll,touch',
      onDrag: (self: Observer) => {
        if (!this.isActive) {
          return
        }

        try {
          this.onDrag.forEach(cb => cb(self))
        } catch (error) {
          console.error('Error on observer callback', error)
        }
      },
      onMove: (self: Observer) => {
        if (!this.isActive) {
          return
        }

        try {
          this.onMove.forEach(cb => cb(self))
        } catch (error) {
          console.error('Error on observer callback', error)
        }
      },
      onUp: (self: Observer) => {
        if (!this.isActive) {
          return
        }

        if (self.event.type === 'scroll') {
          try {
            this.onScroll.forEach(cb => cb(self))
          } catch (error) {
            console.error('Error on observer callback', error)
          }
        }
      },
      onDown: (self: Observer) => {
        if (!this.isActive) {
          return
        }

        if (self.event.type === 'scroll') {
          try {
            this.onScroll.forEach(cb => cb(self))
          } catch (error) {
            console.error('Error on observer callback', error)
          }
        }
      },
    })
  },
}

export default observer
