<template>
  <div v-if="!content.hide_solutions_grid" class="solutions-title">
    <h2 v-if="content.title" class="wrapper title" v-html="content.title"></h2>
  </div>

  <div
    v-if="!content.hide_solutions_grid"
    ref="root"
    class="wrapper solutions-container"
  >
    <div
      ref="container"
      class="solutions__cards"
      :style="{ translate: `${translateCard}px` }"
    >
      <SolutionsCard
        v-for="(card, index) in cards"
        ref="mediaCards"
        :key="card.title"
        :content="card"
        :class="{ open: collapseIndex === index && isMobile }"
        data-card
        class="solutions__cards__item"
        :index="index"
        @open-collapse="openCollapse"
      />
    </div>

    <div class="solutions__nav">
      <button class="solutions__nav__item" @click.prevent="goTo('prev')">
        <SvgSprite symbol="ui-arrow-left" size="24" />
        <span>{{ t('precedent') }}</span>
      </button>

      <button class="solutions__nav__item-right" @click.prevent="goTo('next')">
        <SvgSprite symbol="ui-arrow-left" size="24" />
        <span>{{ t('suivant') }}</span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import { Observer } from 'gsap/Observer'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import SolutionsCard from '@/components/card/Solutions.vue'
import observer from '@/modules/observer'
import { useResize } from '@/utils/aware'

import type { PropType } from 'vue'
import type { SolutionsType } from '@/components/card/Solutions.vue'

export interface SolutionGrid {
  title?: string
  hide_solutions_grid?: boolean
  cards: SolutionsType[]
}

const props = defineProps({
  content: {
    type: Object as PropType<SolutionGrid>,
    required: true,
  },
})

interface CardType {
  plyr?: {
    pause: () => void
  }
}

const root = ref<HTMLElement>()
const container = ref<HTMLElement>()
const cards = ref<SolutionsType[]>([...(props.content?.cards || [])])
const mediaCards = ref<CardType[]>()
const currentIndex = ref(0)
const collapseIndex = ref(-1)
const { t } = useI18n()

const isMobile = ref(false)

let isMoving = false
const cardSpacing = ref(10)

const translateCard = ref()

const goTo = (direction = 'next') => {
  if (isMoving) {
    return
  }
  mediaCards.value?.forEach(card => {
    setTimeout(() => card?.plyr?.pause(), 100)
  })

  let cards = [...(root.value?.querySelectorAll('[data-card]') || [])]
  const lastCardIndex =
    (currentIndex.value - 1 < 0 ? cards.length - 1 : currentIndex.value - 1) %
    cards.length
  const nextCardIndex =
    direction === 'next'
      ? (currentIndex.value + 1) % cards.length
      : lastCardIndex
  const currentCard = cards[currentIndex.value]
  const nextCard = cards[nextCardIndex]
  const count = cards.length - 1

  const tl = gsap.timeline({
    onComplete: () => {
      isMoving = false
      currentIndex.value = nextCardIndex
    },
  })
  gsap.killTweensOf(cards)
  tl.to(cards || {}, {
    duration: 0.2,
    ease: 'none',
  })

  isMoving = true

  if (direction === 'next') {
    cards = cards.filter(card => card !== currentCard)
  } else {
    cards = cards.filter(card => card !== nextCard)
  }

  const cardDuration = 0.1
  const cardEase = 'power2.inOut'

  if (direction === 'next') {
    tl.to(currentCard, {
      duration: cardDuration,
      x: cardSpacing.value,
      opacity: 0,
      ease: cardEase,
      onComplete: () => {
        currentCard?.classList.remove('is-visible')
      },
    })
  } else {
    tl.to(nextCard, {
      duration: cardDuration,
      x: '-=20',
      opacity: 0,
      ease: cardEase,
    })
  }
  tl.addLabel('cardRemoved')

  tl.add(() => cards.forEach(card => card?.classList.remove('is-visible')))
  if (direction === 'next') {
    tl.to(
      cards,
      {
        x: `+=${cardSpacing.value}`,
        zIndex: '+=1',
        stagger: 0.05,
      },
      'cardRemoved-=0.05'
    )
  } else {
    tl.to(
      [...cards].reverse(),
      {
        x: `-=${cardSpacing.value}`,
        zIndex: '-=1',
        stagger: 0.05,
      },
      'cardRemoved-=0.05'
    )
  }
  tl.addLabel('cardAppear', 'cardRemoved+=0.05')

  tl.add(() => nextCard.classList?.add('is-visible'), 'cardAppear')
  if (direction === 'next') {
    tl.set(
      currentCard,
      {
        zIndex: -count,
        x: `-${count * cardSpacing.value}`,
      },
      'cardAppear'
    )
    tl.to(
      currentCard,
      {
        duration: cardDuration,
        x: `-${count * cardSpacing.value}`,
        opacity: 1,
        ease: cardEase,
      },
      'cardAppear'
    )
  } else {
    tl.set(
      nextCard,
      {
        zIndex: 0,
        x: cardSpacing.value,
      },
      'cardAppear'
    )
    tl.to(
      nextCard,
      {
        duration: cardDuration,
        x: 0,
        opacity: 1,
        ease: cardEase,
      },
      'cardAppear'
    )
  }
}

const setCardPosition = () => {
  const cards = root.value?.querySelectorAll('[data-card]') || []
  const [firstCard] = cards

  firstCard?.classList.add('is-visible')

  if (!firstCard) {
    return
  }

  cards.forEach((card, idx) => {
    gsap.set(card, {
      x: -cardSpacing.value * idx,
      zIndex: -idx,
    })
  })
}

const onDrag = (self?: Observer) => {
  if ((self?.deltaX || 0) > 0) {
    goTo('next')
  }

  if ((self?.deltaX || 0) < 0) {
    goTo('prev')
  }
}

const onKeyUp = (e: KeyboardEvent) => {
  if (e.key === 'ArrowRight') {
    goTo('next')
  }

  if (e.key === 'ArrowLeft') {
    goTo('prev')
  }
}

const onWindowResize = () => {
  isMobile.value = window.innerWidth < 1024
}

onMounted(() => {
  useResize(onWindowResize)
  cardSpacing.value = 80

  if (!props.content.hide_solutions_grid) {
    setCardPosition()
    observer.add('onDrag', onDrag, container.value)
    window.addEventListener('keyup', onKeyUp)

    translateCard.value = (cards.value.length * cardSpacing.value) / 2.5
  }
})

onBeforeUnmount(() => {
  observer.remove('onDrag', onDrag, container.value)
  window.removeEventListener('keyup', onKeyUp)
})

const openCollapse = (index: number) => {
  if (isMobile.value && root.value) {
    const cards = root.value.querySelectorAll('[data-card]')

    if (!cards) {
      return
    }

    const currentCard = cards[index].querySelector('.solutions-inner')

    if (index === collapseIndex.value && currentCard) {
      resetSubmenuHeight(currentCard)

      return
    }

    cards?.forEach((element: Element) => {
      const card = element.querySelector('.solutions-inner')

      if (card) {
        resetSubmenuHeight(card)
      }
    })

    gsap.fromTo(
      currentCard,
      {
        ease: 'power3.inOut',
        height: '65px',
      },
      { height: 'auto' }
    )
  }

  collapseIndex.value = index
}

const resetSubmenuHeight = (element: Element) => {
  gsap.to(element || {}, {
    ease: 'power3.inOut',
    height: '65px',
  })

  collapseIndex.value = -1
}
</script>

<style lang="scss" scoped>
$bp: 'l';

.title {
  @include section-title;

  margin-bottom: 0;
  text-align: center;
}

.solutions-title {
  position: relative;
  z-index: $header-z-index - 2;
  padding-top: 10rem;
  padding-bottom: 4rem;

  @include mq($bp) {
    padding-bottom: 10rem;
  }
}

.solutions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;

  @include mq($until: $bp) {
    padding: 0;
    padding-bottom: 4rem;
  }
}

.solutions__cards {
  position: relative;
  width: 95%;

  @include mq($until: $bp) {
    /* stylelint-disable-next-line declaration-no-important */
    translate: none !important;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  @include mq($bp) {
    width: 70%;
  }
}

.solutions__cards__item {
  position: relative;

  &:not(:first-child) {
    @include get-all-space;

    @include mq($until: $bp) {
      /* stylelint-disable-next-line declaration-no-important */
      z-index: 10 !important;
    }

    @include mq($bp) {
      z-index: -1;
    }
  }

  :deep(.solutions) {
    height: 100%;
    background-color: var(--c-bs);
    transition: background-color 0.15s;
  }

  :deep(.solutions-inner) {
    @include mq($bp) {
      opacity: 0;
    }
  }

  &.is-visible {
    :deep(.media-text) {
      background-color: var(--c-bp);
    }

    :deep(.solutions-inner) {
      @include mq($bp) {
        opacity: 1;
      }
    }
  }
}

.solutions__nav {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 6rem;

  @include mq($until: $bp) {
    display: none;
  }
}

.solutions__nav__item,
[class*='solutions__nav__item-'] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 4.8rem;
  padding: 0;
  background: transparent;
  border: 1px solid $c-black;
  border-radius: 50%;
  cursor: pointer;
  transition: border-color 0.15s;

  svg {
    fill: $c-black;
    transition: transform 0.15s;
  }

  span {
    @extend %visually-hidden;
  }

  &:hover {
    background-color: $c-black;
    border-color: var(--c-acc);

    svg {
      fill: $c-white;
    }
  }

  &[class*='-right'] {
    svg {
      transform: rotate(180deg);
    }
  }
}
</style>
